.d-flex {
	display: flex;
}

.flex-row {
	flex-direction: row;
}

.flex-col {
	flex-direction: column;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-baseline {
	justify-content: baseline;
}

.align-center {
	align-items: center;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-baseline {
	align-items: baseline;
}

.gap-1 {
	gap: 0.25rem;
}

.gap-2 {
	gap: 0.5rem;
}

.gap-3 {
	gap: 0.75rem;
}

.gap-4 {
	gap: 1rem;
}

.gap-5 {
	gap: 1.5rem;
}

.gap-6 {
	gap: 2rem;
}
