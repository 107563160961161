/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Swiper
@import 'swiper/swiper-bundle';

body {
	background: #ddd;
}

.alert-button {
	&.danger {
		color: red;
	}
}

ion-note {
	&.md {
		font-size: 0.85rem;
		margin: auto;
		padding: unset;
		margin-right: 1rem;
	}
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.swiper-pagination {
	background: rgba(255, 255, 255, 0.8);
	bottom: 0 !important;
	position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-align: center;
}

ion-item {
	h2,
	h3,
	h4,
	h5 {
		text-align: left;
	}
}

.searchbar-input-container {
	input {
		font-size: 13px !important;
	}
	.searchbar-input.sc-ion-searchbar-md {
		padding-left: 32px;
		padding-right: 27px;
	}
	.searchbar-search-icon.sc-ion-searchbar-md {
		left: 7px !important;
		top: 13px;
		height: 18px;
	}
	.searchbar-clear-button.sc-ion-searchbar-md {
		right: 5px;
	}
}

.swiper-slide {
	height: calc(100vh - 76px) !important;
	overflow-y: scroll !important;

	&.md {
		height: calc(100vh - 88px);
	}

	ion-list {
		margin-bottom: 10px;
	}
}

.swiper-pagination-bullet-active {
	background: rebeccapurple;
}

ion-segment-button.md {
	fa-icon {
		margin-top: 14px;
	}
	ion-label {
		margin-top: 0px;
	}
}

ion-footer.paging {
	padding: 0 constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
	padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

ion-avatar {
	&.district,
	&.club {
		--border-radius: 0px;
	}
}

ion-modal > .ion-page {
	overflow: auto;
}

ion-popover.list-popover {
	--backdrop-opacity: 0.1;
	--backgrop-filter: blur(4px)
	--box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
	--color: white;
	--width: 80vw;
	backdrop-filter: blur(5px);
	
}
