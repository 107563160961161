$spriteSrc: '../assets/flags.png';

.flag {
	&:not(.bg-np) {
		box-shadow: 0px 0px 0px 1px black;
	}
	margin-left: 1px;
	display: block;
}

.bg-vg {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -81px -10px;
}

.bg-vi {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -210px;
}

.bg-vn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -250px;
}

.bg-vu {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -229px -450px;
}

.bg-wf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -290px;
}

.bg-ws {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -50px;
}

.bg-xk {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -407px -690px;
}

.bg-ye {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -330px;
}

.bg-yt {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -370px;
}

.bg-za {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -410px;
}

.bg-zm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -450px;
}

.bg-zw {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -50px;
}

.bg-sy {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -554px -490px;
}

.bg-sz {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -10px -530px;
}

.bg-tc {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -90px;
}

.bg-td {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -60px -530px;
}

.bg-tf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -110px -530px;
}

.bg-tg {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -328px -490px;
}

.bg-th {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -160px -530px;
}

.bg-tj {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -90px;
}

.bg-tk {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -141px -10px;
}

.bg-tl {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -141px -50px;
}

.bg-tm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -210px -530px;
}

.bg-tn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -260px -530px;
}

.bg-to {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -141px -90px;
}

.bg-tr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -310px -530px;
}

.bg-tt {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -282px -450px;
}

.bg-tv {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -130px;
}

.bg-tw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -360px -530px;
}

.bg-tz {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -410px -530px;
}

.bg-ua {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -460px -530px;
}

.bg-ug {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -510px -530px;
}

.bg-us {
	width: 38px;
	height: 20px;
	background: url($spriteSrc) -70px -410px;
}

.bg-um {
	width: 38px;
	height: 20px;
	background: url($spriteSrc) -128px -410px;
}

.bg-uy {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -10px;
}

.bg-uz {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -130px;
}

.bg-va {
	width: 20px;
	height: 20px;
	background: url($spriteSrc) -756px -370px;
}

.bg-vc {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -50px;
}

.bg-ve {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -90px;
}

.bg-pw {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -380px -490px;
}

.bg-py {
	width: 36px;
	height: 20px;
	background: url($spriteSrc) -417px -410px;
}

.bg-qa {
	width: 51px;
	height: 20px;
	background: url($spriteSrc) -10px -10px;
}

.bg-re {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -130px;
}

.bg-ro {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -170px;
}

.bg-rs {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -210px;
}

.bg-ru {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -250px;
}

.bg-rw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -290px;
}

.bg-sa {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -330px;
}

.bg-sb {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -130px;
}

.bg-sc {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -201px -10px;
}

.bg-sd {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -201px -50px;
}

.bg-se {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -432px -490px;
}

.bg-sg {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -370px;
}

.bg-sh {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -201px -90px;
}

.bg-si {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -201px -130px;
}

.bg-sj {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -455px -690px;
}

.bg-sk {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -410px;
}

.bg-sl {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -450px;
}

.bg-sm {
	width: 27px;
	height: 20px;
	background: url($spriteSrc) -756px -50px;
}

.bg-sn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -490px;
}

.bg-so {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -606px -530px;
}

.bg-sr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -10px -570px;
}

.bg-ss {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -170px;
}

.bg-st {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -170px;
}

.bg-sv {
	width: 35px;
	height: 20px;
	background: url($spriteSrc) -10px -450px;
}

.bg-sx {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -60px -570px;
}

.bg-mx {
	width: 35px;
	height: 20px;
	background: url($spriteSrc) -65px -450px;
}

.bg-my {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -170px;
}

.bg-mz {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -110px -570px;
}

.bg-na {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -160px -570px;
}

.bg-nc {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -170px;
}

.bg-ne {
	width: 23px;
	height: 20px;
	background: url($spriteSrc) -756px -290px;
}

.bg-ng {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -210px;
}

.bg-nf {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -210px;
}

.bg-ni {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -335px -450px;
}

.bg-no {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -503px -690px;
}

.bg-nl {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -210px -570px;
}

.bg-np {
	width: 16px;
	height: 20px;
	background: url($spriteSrc) -756px -450px;
}

.bg-nr {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -210px;
}

.bg-nu {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -210px;
}

.bg-nz {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -10px;
}

.bg-pa {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -260px -570px;
}

.bg-om {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -50px;
}

.bg-pe {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -310px -570px;
}

.bg-pf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -360px -570px;
}

.bg-pg {
	width: 27px;
	height: 20px;
	background: url($spriteSrc) -756px -90px;
}

.bg-ph {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -90px;
}

.bg-pk {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -410px -570px;
}

.bg-pl {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -484px -490px;
}

.bg-pm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -460px -570px;
}

.bg-pn {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -130px;
}

.bg-pr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -510px -570px;
}

.bg-pt {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -560px -570px;
}

.bg-ps {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -170px;
}

.bg-lk {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -261px -210px;
}

.bg-lr {
	width: 38px;
	height: 20px;
	background: url($spriteSrc) -186px -410px;
}

.bg-ls {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -10px -610px;
}

.bg-lt {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -388px -450px;
}

.bg-lu {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -441px -450px;
}

.bg-lv {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -250px;
}

.bg-ly {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -250px;
}

.bg-ma {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -60px -610px;
}

.bg-mc {
	width: 25px;
	height: 20px;
	background: url($spriteSrc) -756px -250px;
}

.bg-md {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -250px;
}

.bg-me {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -250px;
}

.bg-mf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -110px -610px;
}

.bg-mg {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -160px -610px;
}

.bg-mh {
	width: 38px;
	height: 20px;
	background: url($spriteSrc) -244px -410px;
}

.bg-mk {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -250px -250px;
}

.bg-ml {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -210px -610px;
}

.bg-mm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -260px -610px;
}

.bg-mn {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -10px;
}

.bg-mo {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -310px -610px;
}

.bg-mp {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -50px;
}

.bg-mq {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -360px -610px;
}

.bg-mr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -410px -610px;
}

.bg-ms {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -90px;
}

.bg-mt {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -460px -610px;
}

.bg-mu {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -510px -610px;
}

.bg-mv {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -560px -610px;
}

.bg-mw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -10px;
}

.bg-il {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -551px -690px;
}

.bg-im {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -130px;
}

.bg-in {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -50px;
}

.bg-io {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -170px;
}

.bg-iq {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -90px;
}

.bg-ir {
	width: 35px;
	height: 20px;
	background: url($spriteSrc) -120px -450px;
}

.bg-is {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -599px -690px;
}

.bg-it {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -130px;
}

.bg-je {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -10px;
}

.bg-jm {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -210px;
}

.bg-jo {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -321px -250px;
}

.bg-jp {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -170px;
}

.bg-ke {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -210px;
}

.bg-kg {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -50px;
}

.bg-kh {
	width: 31px;
	height: 20px;
	background: url($spriteSrc) -554px -90px;
}

.bg-ki {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -290px;
}

.bg-km {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -90px;
}

.bg-kn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -250px;
}

.bg-kp {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -290px;
}

.bg-kr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -290px;
}

.bg-kw {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -290px;
}

.bg-ky {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -290px;
}

.bg-kz {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -250px -290px;
}

.bg-la {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -330px;
}

.bg-lb {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -370px;
}

.bg-lc {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -310px -290px;
}

.bg-li {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -130px;
}

.bg-gb {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -330px;
}

.bg-gb-nir {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -330px;
}

.bg-gb-sct {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -170px;
}

.bg-gb-wls {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -210px;
}

.bg-gd {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -250px;
}

.bg-ge {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -410px;
}

.bg-gf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -450px;
}

.bg-gg {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -490px;
}

.bg-gh {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -530px;
}

.bg-gi {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -330px;
}

.bg-gl {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -570px;
}

.bg-gm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -656px -610px;
}

.bg-gn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -10px -650px;
}

.bg-gp {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -60px -650px;
}

.bg-gq {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -110px -650px;
}

.bg-gr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -160px -650px;
}

.bg-gs {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -330px;
}

.bg-gt {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -554px -10px;
}

.bg-gu {
	width: 37px;
	height: 20px;
	background: url($spriteSrc) -360px -410px;
}

.bg-gw {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -250px -330px;
}

.bg-gy {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -290px;
}

.bg-hk {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -210px -650px;
}

.bg-hm {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -310px -330px;
}

.bg-hn {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -10px;
}

.bg-hr {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -50px;
}

.bg-ht {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -330px;
}

.bg-hu {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -90px;
}

.bg-id {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -260px -650px;
}

.bg-ie {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -130px;
}

.bg-cr {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -370px;
}

.bg-cu {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -170px;
}

.bg-cv {
	width: 34px;
	height: 20px;
	background: url($spriteSrc) -175px -450px;
}

.bg-cw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -310px -650px;
}

.bg-cx {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -210px;
}

.bg-cy {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -360px -650px;
}

.bg-cz {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -410px -650px;
}

.bg-de {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -410px;
}

.bg-dj {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -460px -650px;
}

.bg-dk {
	width: 26px;
	height: 20px;
	background: url($spriteSrc) -756px -210px;
}

.bg-dm {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -250px;
}

.bg-do {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -510px -650px;
}

.bg-dz {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -560px -650px;
}

.bg-ec {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -610px -650px;
}

.bg-ee {
	width: 31px;
	height: 20px;
	background: url($spriteSrc) -554px -130px;
}

.bg-eg {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -10px;
}

.bg-eh {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -290px;
}

.bg-er {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -381px -330px;
}

.bg-es {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -50px;
}

.bg-et {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -370px;
}

.bg-fi {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -501px -450px;
}

.bg-fj {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -70px -370px;
}

.bg-fk {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -130px -370px;
}

.bg-fm {
	width: 38px;
	height: 20px;
	background: url($spriteSrc) -302px -410px;
}

.bg-fo {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -647px -690px;
}

.bg-fr {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -90px;
}

.bg-ga {
	width: 27px;
	height: 20px;
	background: url($spriteSrc) -756px -130px;
}

.bg-gb-eng {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -10px -490px;
}

.bg-bi {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -63px -490px;
}

.bg-bj {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -130px;
}

.bg-bl {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -170px;
}

.bg-bm {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -190px -370px;
}

.bg-bn {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -250px -370px;
}

.bg-bo {
	width: 29px;
	height: 20px;
	background: url($spriteSrc) -260px -690px;
}

.bg-bq {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -210px;
}

.bg-br {
	width: 29px;
	height: 20px;
	background: url($spriteSrc) -309px -690px;
}

.bg-bs {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -310px -370px;
}

.bg-bt {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -250px;
}

.bg-bv {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -695px -690px;
}

.bg-bw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -290px;
}

.bg-by {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -370px -370px;
}

.bg-bz {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -116px -490px;
}

.bg-ca {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -10px;
}

.bg-cc {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -50px;
}

.bg-cd {
	width: 27px;
	height: 20px;
	background: url($spriteSrc) -756px -170px;
}

.bg-cf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -330px;
}

.bg-cg {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -370px;
}

.bg-ch {
	width: 20px;
	height: 20px;
	background: url($spriteSrc) -756px -410px;
}

.bg-ci {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -410px;
}

.bg-ck {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -90px;
}

.bg-cl {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -450px;
}

.bg-cm {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -490px;
}

.bg-cn {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -530px;
}

.bg-co {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -570px;
}

.bg-ad {
	width: 29px;
	height: 20px;
	background: url($spriteSrc) -358px -690px;
}

.bg-ae {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -130px;
}

.bg-af {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -170px;
}

.bg-ag {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -610px;
}

.bg-ai {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -210px;
}

.bg-al {
	width: 28px;
	height: 20px;
	background: url($spriteSrc) -756px -10px;
}

.bg-am {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -250px;
}

.bg-ao {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -706px -650px;
}

.bg-aq {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -10px -690px;
}

.bg-ar {
	width: 32px;
	height: 20px;
	background: url($spriteSrc) -554px -50px;
}

.bg-as {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -290px;
}

.bg-at {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -60px -690px;
}

.bg-au {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -330px;
}

.bg-aw {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -110px -690px;
}

.bg-ax {
	width: 31px;
	height: 20px;
	background: url($spriteSrc) -554px -170px;
}

.bg-az {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -441px -370px;
}

.bg-ba {
	width: 40px;
	height: 20px;
	background: url($spriteSrc) -10px -410px;
}

.bg-bb {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -160px -690px;
}

.bg-bd {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -169px -490px;
}

.bg-be {
	width: 23px;
	height: 20px;
	background: url($spriteSrc) -756px -330px;
}

.bg-bf {
	width: 30px;
	height: 20px;
	background: url($spriteSrc) -210px -690px;
}

.bg-bg {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -222px -490px;
}

.bg-bh {
	width: 33px;
	height: 20px;
	background: url($spriteSrc) -275px -490px;
}
