// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--highlighted: #ffffaa;

	// PLAYER CLASS COLORS

	--ion-color-a: #222428;
	--ion-color-a-rgb: 34, 36, 40;
	--ion-color-a-contrast: #ffffff;
	--ion-color-a-contrast-rgb: 255, 255, 255;
	--ion-color-a-shade: #1e2023;
	--ion-color-a-tint: #383a3e;

	--ion-color-b: #e91e63;
	--ion-color-b-rgb: 233, 30, 99;
	--ion-color-b-contrast: #ffffff;
	--ion-color-b-contrast-rgb: 255, 255, 255;
	--ion-color-b-shade: #cd1a57;
	--ion-color-b-tint: #eb3573;

	--ion-color-c: #ff9800;
	--ion-color-c-rgb: 255, 152, 0;
	--ion-color-c-contrast: #000000;
	--ion-color-c-contrast-rgb: 0, 0, 0;
	--ion-color-c-shade: #e08600;
	--ion-color-c-tint: #ffa21a;

	--ion-color-d: #3d8b3d;
	--ion-color-d-rgb: 61, 139, 61;
	--ion-color-d-contrast: #ffffff;
	--ion-color-d-contrast-rgb: 255, 255, 255;
	--ion-color-d-shade: #367a36;
	--ion-color-d-tint: #509750;

	--ion-color-e: #2196f3;
	--ion-color-e-rgb: 33, 150, 243;
	--ion-color-e-contrast: #ffffff;
	--ion-color-e-contrast-rgb: 255, 255, 255;
	--ion-color-e-shade: #1d84d6;
	--ion-color-e-tint: #37a1f4;

	--ion-color-f: #673ab7;
	--ion-color-f-rgb: 103, 58, 183;
	--ion-color-f-contrast: #ffffff;
	--ion-color-f-contrast-rgb: 255, 255, 255;
	--ion-color-f-shade: #5b33a1;
	--ion-color-f-tint: #764ebe;

	--ion-color-g: #3f51b5;
	--ion-color-g-rgb: 63, 81, 181;
	--ion-color-g-contrast: #ffffff;
	--ion-color-g-contrast-rgb: 255, 255, 255;
	--ion-color-g-shade: #37479f;
	--ion-color-g-tint: #5262bc;

	--ion-color-h: #92949c;
	--ion-color-h-rgb: 146, 148, 156;
	--ion-color-h-contrast: #ffffff;
	--ion-color-h-contrast-rgb: 0, 0, 0;
	--ion-color-h-shade: #808289;
	--ion-color-h-tint: #9d9fa6;

	--ion-color-gold: #ffd700;
	--ion-color-gold-rgb: 255, 215, 0;
	--ion-color-gold-contrast: #000000;
	--ion-color-gold-contrast-rgb: 0, 0, 0;
	--ion-color-gold-shade: #e0bd00;
	--ion-color-gold-tint: #ffdb1a;

	--ion-color-silver: #c0c0c0;
	--ion-color-silver-rgb: 192, 192, 192;
	--ion-color-silver-contrast: #000000;
	--ion-color-silver-contrast-rgb: 0, 0, 0;
	--ion-color-silver-shade: #a9a9a9;
	--ion-color-silver-tint: #c6c6c6;

	--ion-color-bronze: #cd7f32;
	--ion-color-bronze-rgb: 205, 127, 50;
	--ion-color-bronze-contrast: #000000;
	--ion-color-bronze-contrast-rgb: 0, 0, 0;
	--ion-color-bronze-shade: #b4702c;
	--ion-color-bronze-tint: #d28c47;
}

$cat: 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'gold', 'silver', 'bronze';

@each $c in $cat {
	.ion-color-#{$c} {
		--ion-color-base: var(--ion-color-#{$c});
		--ion-color-base-rgb: var(--ion-color-#{$c}-rgb);
		--ion-color-contrast: var(--ion-color-#{$c}-contrast);
		--ion-color-contrast-rgb: var(--ion-color-#{$c}-contrast-rgb);
		--ion-color-shade: var(--ion-color-#{$c}-shade);
		--ion-color-tint: var(--ion-color-#{$c}-tint);
	}
}
